<template>
  <AdminLayout
    :title="project.job_id || 'Create project'"
  >
    <v-form
      @submit.prevent="submit"
    >
      <v-text-field
        v-model="form.job_id"
        label="Job ID"
        :disabled="!!$route.params.job_id"
      >
      </v-text-field>
      <v-text-field
        v-model="form.floors_number"
        label="Number of floors"
      >
      </v-text-field>
      <v-text-field
          v-model="form.wpp_link"
          label="WPP Link"
      >
      </v-text-field>
      <v-btn
        type="submit"
        color="primary"
      >
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </v-form>
  </AdminLayout>
</template>

<script>
import AdminLayout from '../../layouts/AdminLayout'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    AdminLayout,
  },

  data: () => ({
    form: {
      job_id: '',
      user_id: null,
      floors_number: null,
      wpp_link: null,
    },
  }),

  async created () {
    if (this.$route.params.job_id) {
      await this.fetchProject(this.$route.params.job_id)
      this.form.job_id = this.project.job_id
      this.form.floors_number = this.project.floors_number
      this.form.wpp_link = this.project.wpp_link
    } else {
      this.form.user_id = this.user.id
    }
  },

  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters('project', ['project']),
  },

  methods: {
    ...mapActions('project', ['createProject', 'fetchProject', 'updateProject']),

    async submit () {
      if (this.$route.params.job_id) {
        await this.updateProject(this.form)
        this.$router.push(`/project/${this.project.job_id}`)
      } else {
        this.createProject(this.form)
      }
    },
  },
}
</script>
